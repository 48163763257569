@import "./../node_modules/consent-ui-components/lib/esm/Consent.scss";

.App{
    padding-left: 50px;
    padding-top: 20px;
}
body{
    justify-content: center;
}

.alignConsent{
    border:solid 1px black;
    box-shadow: 2px 2px 5px;
    margin-top: 30px;
    height: 33.33%;
    width: 33.33%;
    padding: 0.5%;
}

@media (max-width: 900px) {
    .alignConsent {
        width: 70%;
    }
}

@media (max-width: 600px) {
    .alignConsent {
        width: 90%;
    }
}

.channelChoicesTitle{
    padding-bottom:0.5ex;
    padding-top: 0ex;
}

#consentReloadButton{
    text-transform: none;
    font-weight: bold;
}

#consentUpdateButton{
    text-transform: none;
    font-weight: bold;
}

#consentWidrawalButton{
    margin-left: 10px;
    text-transform: none;
    font-weight: bold;
}

#consentSubmitButton{
    text-transform: none;
    font-weight: bold;
}